import * as yup from 'yup';

export const formHeaders = [
    '#',
    'Business Legal Name',
    'Authorized Signer - Name',
    'Authorized Signer - Phone',
    'Authorized Signer - Email',
    // 'Billing Contact - Billing Address',
    // 'Billing Contact - Billing Phone',
    // 'City',
    // 'State',
    // 'Zip',
    'Site Phone',
];

export const formSchema = yup.object().shape({
    businessLegalName: yup.string().required(),
    authorizedSignerName: yup.string().required(),
    authorizedSignerPhone: yup.string().required('Required field'),
    authorizedSignerEmail: yup.string().required('Required field'),
    // billingAddress: yup.string().required(),
    // billingPhone: yup.string().required(),
    // city: yup.string().required(),
    // state: yup.string().required(),
    // zip: yup
    //     .string()
    //     .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Enter a valid zip code')
    //     .required('Required field'),
    // ckeBrands: yup.string().required(),
});

export const formSchemaRows = yup.array().of(
    yup.object().shape({
        key: yup.number().required(),
        address: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup
            .string()
            .matches(/^$|^\d{5}(?:[-\s]\d{4})?$/)
            .notRequired(),
        phone: yup.string()
    })
);
